import { defineRule } from "vee-validate";
import moment from "moment";

defineRule("required", (value, [name, isSelect]) => {
  if (!value || value.length <= 0) {
    if (name) {
      if (!isSelect || isSelect == false) {
        return `Please enter ${name}`;
      } else {
        return `Please select ${name}`;
      }
    }
    return "This field is required";
  }
  return true;
});

defineRule("requiredSelect", (value, [name, isSelect]) => {
  if (!value || value.length <= 0) {
    if (name) {
      if (!isSelect || isSelect == false) {
        return `Please enter ${name}`;
      } else {
        return `Please select ${name}`;
      }
    }
    return "This field is required";
  }
  return true;
});

defineRule("message", (value, [name]) => {
  if (value && value == "select") {
    return `Please select ${name}`;
  }
  return true;
});

defineRule("email", (value) => {
  if (
    value &&
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return "Please enter valid email";
  }
  return true;
});

defineRule("phone", (value) => {
  if (value && !/^[0-9]{10}$/.test(value)) {
    return "Please enter valid contact number";
  }
  return true;
});

defineRule("pincode", (value) => {
  if (value && !/^[0-9]{6}$/.test(value)) {
    return "Please enter valid pin code";
  }
  return true;
});

defineRule("userName", (value, [name]) => {
  if (value && !/^[A-Za-z][A-Za-z_]{1,}$/.test(value)) {
    if (name) {
      return `Please enter valid ${name}`;
    }
    return "Please enter valid name";
  }
  return true;
});

defineRule("minLength", (value, [limit]) => {
  if (value.length < limit) {
    return `This field must be at least ${limit} characters`;
  }
  return true;
});

defineRule("password", (value) => {
  if (
    value &&
    !/(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
      value
    )
  ) {
    return "Password must contain at least eight characters, at least one number and both lower and uppercase letters and special characters";
  }
  return true;
});

defineRule("confirmed", (value, [target]) => {
  if (value === target) {
    return true;
  }
  return "Passwords must match";
});
defineRule("aadhaar", (value) => {
  if (
    value &&
    !/^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/.test(
      value
    )
  ) {
    return "Please enter valid aadhar no";
  }
  return true;
});
defineRule("PAN", (value) => {
  if (value && !/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(value)) {
    return "Please enter valid PAN no";
  }
  return true;
});

defineRule("ageValidation", (value) => {
  var newValue = moment(String(value)).format("Y/MM/DD");
  var optimizedBirthday = newValue.replace(/-/g, "/");

  //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
  var myBirthday = new Date(optimizedBirthday);
  // set current day on 01:00:00 hours GMT+0100 (CET)
  var currentDate = new Date().toJSON().slice(0, 10) + " 01:00:00";
  // calculate age comparing current date and borthday
  var myAge = ~~((Date.now(currentDate) - myBirthday) / 31557600000);
  console.log("the value of the age is : ", myAge);
  if (myAge < 18) {
    return "guarantor age must be 18+";
  } else {
    return true;
  }
});

defineRule("price", (value) => {
  if (value && !/^[1-9]\d*(\.\d{1,2}?)?$/.test(value)) {
    return "Please enter valid price";
  }
  return true;
});

defineRule("tenDigitNumber", (value) => {
  if (value && !/^\d{10}$/.test(value)) {
    return "Please enter a valid 10-digit number";
  }
  return true;
});

defineRule("numeric", (value) => {
  if (value && !/^[0-9]\d*(\.\d{1,2}?)?$/.test(value)) {
    return "Please enter numeric values only";
  }
  return true;
});

defineRule("maxValue", (value) => {
  console.log("maxValue", value);
  value = parseFloat(value);
  if (value && (isNaN(value) || value > 100)) {
      return "Maximum value should be 100";
  }
  return true;
});

defineRule("discountPrice", (value, [limit]) => {
  console.log("discountPrice", [value, limit]);
  value = parseFloat(value);
  if (value && isNaN(value) || value > limit) {
      return "Maximum value should be " + limit;
  }
  return true;
});

defineRule("KILOMETER", (value, name) => {
  if (
    value === null ||
    value === undefined ||
    value === "" ||
    value < 0 ||
    isNaN(value)
  ) {
    return `Please Enter ${name} value `; // Validation failed
  }
  return true; // Validation passed
});

defineRule("multiply", (value) => {
  if (value.length < 4) {
    return "Value must be minimum 5000";
  } else if (value % 5000 === 0) {
    return true;
  } else {
    return "Value must be multiple of 5000";
  }
});

defineRule("minValue", (value, [limit]) => {
  if (value < limit) {
    return `This field minimum value is  ${limit}`;
  }
  return true;
});


defineRule("mobileNumber", (value) => {
  if (!value) {
    return true;
  }
  const mobileNumberPattern = /^[0-9]{10}$/;
  if (!mobileNumberPattern.test(value)) {
    return "Please enter a valid 10-digit mobile number";
  }
  return true;
});
defineRule("selectAddress", (value) => {
  if (!value) {
    return "Please select an address";
  }
  return true;
});

defineRule("gst", (value) => {
  if (value && !/^[A-Z0-9]{15}$/.test(value)) {
    return "Please enter valid GST number";
  }
  return true;
});

defineRule("panNumber", (value) => {
  if (!value) {
    return "PAN number is required";
  }
  if (value && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value)) {
    return "Please enter a valid PAN number";
  }
  return true;
});

defineRule("aadharNumber", (value) => {
  if (!value) {
    return "Aadhaar number is required";
  }
  if (value && !/^\d{12}$/.test(value)) {
    return "Please enter a valid Aadhaar number";
  }
  return true;
});

defineRule("selectPickupAndDrop", (value) => {
  if (!value) {
    return "Please select pickup and drop";
  }
  return true;
});
defineRule("maxRewardAmount", (value, [limit]) => {
  if (parseInt(value) > parseInt(limit)) {
    return `The maximum withdrawable amount is ₹  ${limit}`;
  }
  return true;
});
defineRule("minRewardAmount", (value, [limit]) => {
  if (parseInt(value) < parseInt(limit)) {
    return `The minimum withdrawable amount is ₹ ${limit}`;
  }
  return true;
});
