import { createWebHistory, createRouter } from "vue-router";
import Login from "@/views/Login/Login.vue";
import Otp from "@/views/Login/otp.vue";
import SetAddress from "@/views/Login/Address.vue";
import ChooseOutlet from "@/views/Login/ChooseOutlet.vue";
import WorkshopDetail from "@/views/Onboarding/WorkshopDetail.vue";
import OnboardingSteps from "@/views/Onboarding/OnboardingSteps.vue";
import PersonalDetail from "@/views/Onboarding/PersonalDetail.vue";
import PhotoVideos from "@/views/Onboarding/PhotoVideos.vue";
import SelectVehicle from "@/views/Onboarding/SelectVehicle.vue";
import ChooseBrand from "@/views/Onboarding/ChooseManufacture.vue";
import FuelType from "@/views/Onboarding/FuelType.vue";
import BankDetails from "@/views/Onboarding/BankDetails.vue";
import WorkingTime from "@/views/Onboarding/WorkingTime.vue";
import CashlessInsurance from "@/views/Onboarding/CashlessInsurance.vue";
import AgentCode from "@/views/Onboarding/AgentCode.vue";
import SelectServices from "@/views/Onboarding/SelectServices.vue";
import ServiceDetails from "@/views/Onboarding/ServiceDetails.vue";
import VehicleModel from "@/views/Onboarding/VehicleModel.vue";
import HomePage from "@/views/Home/HomePage.vue";
import InvoicePage from "@/views/Invoice/InvoicePage.vue";
import RateCard from "@/views/Onboarding/RateCard.vue";
import SetServiceCost from "@/views/Onboarding/SetServiceCost.vue";
import SetImporteCost from "@/views/Onboarding/SetImporteCost.vue";
import SetServiceManufactureCost from "@/views/Onboarding/SetServiceManufactureCost.vue";
import ChooseTyreBrands from "@/views/Onboarding/SelectServices/ChooseTyreBrands";
import ChooseBatteryBrands from "@/views/Onboarding/SelectServices/ChooseBatteryBrands";
import ChooseCNGBrands from "@/views/Onboarding/SelectServices/ChooseCNGBrands";
import YourOrder from "@/views/Order/YourOrder.vue";
import Chat from "@/views/Chat/Chat.vue";
import Conversation from "@/views/Chat/Conversation.vue";
import AccountNav from "@/views/Account/Account.vue";
import InvoiceAddress from "@/views/Account/invoiceAddress.vue";
import SettingsList from "@/views/Settings/SettingsList.vue";
import ServiceReport from "@/views/Account/ServiceReport.vue";
import ServiceCost from "@/views/Settings/ServiceCost.vue";
import LoginMobileNumbers from "@/views/Settings/LoginMobileNumbers.vue";
import MyProfile from "@/views/Settings/MyProfile.vue";
import PartnerDetail from "@/views/Settings/PartnerDetail.vue";
import RewardHistory from "@/views/Settings/RewardHistory.vue";
import NotificationPage from "@/views/Settings/NotificationPage.vue";
import CloseAccount from "@/views/Settings/CloseAccount.vue";
import CloseAccountOTP from "@/views/Settings/CloseAccountOTP.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsCondition from "@/views/TermsCondition.vue";
import OrderDetail from "@/views/Order/OrderDetail.vue";
import FaqPage from "@/views/FaqPage.vue";
import RequestCallback from "@/views/RequestCallback.vue";
import QuotDetails from "@/views/Quotation/quotation-detail.vue";
import DeactivateWorkshop from "@/views/Settings/DeactivateWorkshop.vue";
import ActivateWorkshop from "@/views/Settings/ActivateWorkshop.vue";
import testChat from "@/views/Chat/testChat.vue";
import testVendorChat from "@/views/Chat/testVendorChat.vue";
import AppLogin from "@/views/Login/AppLogin.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/otp",
    name: "Otp",
    component: Otp,
  },
  {
    path: "/test",
    name: "testChat",
    component: testChat,
  },
  {
    path: "/test-vendor",
    name: "testVendorChat",
    component: testVendorChat,
  },
  {
    path: "/app-login",
    name: "AppLogin",
    component: AppLogin,
  },
  {
    path: "",
    name: "Main",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/address",
        name: "SetAddress",
        component: SetAddress,
      },
      {
        path: "/choose-outlet",
        name: "ChooseOutlet",
        component: ChooseOutlet,
      },
      {
        path: "/onboarding-steps",
        name: "OnboardingSteps",
        component: OnboardingSteps,
      },
      {
        path: "/personal-details",
        name: "PersonalDetail",
        component: PersonalDetail,
      },
      {
        path: "/workshop-details",
        name: "WorkshopDetail",
        component: WorkshopDetail,
      },
      {
        path: "/photos-video",
        name: "PhotoVideos",
        component: PhotoVideos,
      },
      {
        path: "/select-vehicle",
        name: "SelectVehicle",
        component: SelectVehicle,
      },
      {
        path: "/choose-manufacture",
        name: "ChooseManufacture",
        component: ChooseBrand,
      },
      {
        path: "/select-fuel-type",
        name: "FuelType",
        component: FuelType,
      },
      {
        path: "/bank-details",
        name: "BankDetails",
        component: BankDetails,
      },
      {
        path: "/home",
        name: "HomePage",
        component: HomePage,
      },
      {
        path: "/invoice/:id/:type",
        name: "InvoicePage",
        component: InvoicePage,
      },
      {
        path: "/rate-card",
        name: "RateCard",
        component: RateCard,
      },
      {
        path: "/working-time",
        name: "WorkingTime",
        component: WorkingTime,
      },
      {
        path: "/insurance",
        name: "CashlessInsurance",
        component: CashlessInsurance,
      },
      {
        path: "/agent-code",
        name: "AgentCode",
        component: AgentCode,
      },
      {
        path: "/services",
        name: "SelectServices",
        component: SelectServices,
      },
      {
        path: "/service-details",
        name: "ServiceDetails",
        component: ServiceDetails,
      },
      {
        path: "/vehicle-modal/:id/:btn",
        name: "VehicleModel",
        component: VehicleModel,
      },
      {
        path: "/set-service-cost",
        name: "SetServiceCost",
        component: SetServiceCost,
      },
      {
        path: "/import-service-cost",
        name: "SetImporteCost",
        component: SetImporteCost,
      },
      {
        path: "/set-service-manufacture-cost/:id",
        name: "SetServiceManufactureCost",
        component: SetServiceManufactureCost,
      },
      {
        path: "/choose-tyre-brands",
        name: "ChooseTyreBrands",
        component: ChooseTyreBrands,
      },
      {
        path: "/choose-battery-brands",
        name: "ChooseBatteryBrands",
        component: ChooseBatteryBrands,
      },
      {
        path: "/choose-cng-brands",
        name: "ChooseCNGBrands",
        component: ChooseCNGBrands,
      },
      {
        path: "/orders",
        name: "YourOrder",
        component: YourOrder,
      },
      {
        path: "/order-details/:uuid",
        name: "OrderDetail",
        component: OrderDetail,
      },
      {
        path: "/order-chat",
        name: "OrderChat",
        component: Chat,
      },
      {
        path: "/chat/:id",
        name: "Chat",
        component: Chat,
      },
      {
        path: "/conversation",
        name: "Conversation",
        component: Conversation,
      },
      {
        path: "/invoice-address",
        name: "InvoiceAddress",
        component: InvoiceAddress,
      },
      {
        path: "/account",
        name: "AccountNav",
        component: AccountNav,
      },
      {
        path: "/settings",
        name: "SettingsList",
        component: SettingsList,
      },
      {
        path: "/service-report",
        name: "ServiceReport",
        component: ServiceReport,
      },
      {
        path: "/service-cost",
        name: "ServiceCost",
        component: ServiceCost,
      },
      {
        path: "/login-moibile-number",
        name: "LoginMobileNumbers",
        component: LoginMobileNumbers,
      },
      {
        path: "/profile",
        name: "MyProfile",
        component: MyProfile,
      },
      {
        path: "/partner-detail",
        name: "PartnerDetail",
        component: PartnerDetail,
      },
      {
        path: "/reward-history",
        name: "RewardHistory",
        component: RewardHistory,
      },
      {
        path: "/notification",
        name: "NotificationPage",
        component: NotificationPage,
      },
      {
        path: "/close-account",
        name: "CloseAccount",
        component: CloseAccount,
      },
      {
        path: "/close-account-otp",
        name: "CloseAccountOTP",
        component: CloseAccountOTP,
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
      },
      {
        path: "/terms-condition",
        name: "TermsCondition",
        component: TermsCondition,
      },
      {
        path: "/faq",
        name: "FaqPage",
        component: FaqPage,
      },
      {
        path: "/request-callback",
        name: "RequestCallback",
        component: RequestCallback,
      },
      {
        path: "/quotation-detail/:id",
        name: "QuotDetails",
        component: QuotDetails,
      },
      {
        path: "/deactivate-Workshop",
        name: "DeactivateWorkshop",
        component: DeactivateWorkshop,
      },
      {
        path: "/activate-Workshop",
        name: "ActivateWorkshop",
        component: ActivateWorkshop,
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("access_token");
  if (!token && window.location.href.includes("/invoice/")) {
    localStorage.setItem("next_url", window.location.pathname);
  } 
  const isAuthRequired = to.matched.some((record) => record.meta.requiresAuth);

  if (isAuthRequired && !token) {
    next({
      path: "/login",
      params: {
        nextUrl: to.fullPath,
      },
    });
  } else if ((to.path === "/" || to.path === "/login") && token) {
    next({
      path: "/choose-outlet",
      params: {
        nextUrl: to.fullPath,
      },
    });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token") == null) {
      next({
        path: "/login",
        params: {
          nextUrl: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    if (to.path === "/" || to.path === "/login") {
      if (localStorage.getItem("access_token") != null) {
        next({
          path: "/choose-outlet",
          params: {
            nextUrl: to.fullPath,
          },
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
