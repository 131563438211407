<template>
  <div class="login-page">
    <!-- <div class="container"><h1>Login</h1></div> -->
    <section>
      <div class="container-fluid min-vh-100 h-xs-auto">
        <div class="row min-vh-100 h-xs-auto">
          <div
            class="col-lg-6 min-vh-100 h-xs-auto d-flex align-items-center order-2 order-lg-1"
            style="background-color: #00292e"
          >
            <div>
              <div class="text-center pt-lg-5 pt-3 image-body">
                <img
                  :src="image"
                  width="500"
                  height="400"
                  class="img-fluid login-img"
                  alt=""
                />
              </div>
              <div id="login-carsousel" class="carousel slide login-carsousel">
                <div class="carousel-indicators">
                  <span v-for="(res, i) in banner_images" :key="i">
                    <button
                      v-on:click="showCurrentImage(res, i)"
                      type="button"
                      data-bs-target="#login-carsousel"
                      data-bs-slide-to="0"
                      :class="index == i ? 'active' : ''"
                      aria-current="true"
                      aria-label="Slide 1"
                    >11</button>
                  </span>
                </div>
                <div class="carousel-inner text-center">
                  <template v-for="(res, i) in banner_images" :key="i">
                    <div class="carousel-item" :class="index == i ? 'active' : ''">
                      <h2 class="title">{{ res.title }}</h2>
                      <p class="description">{{ res.description }}</p>
                    </div>
                  </template>
                  <div class="carousel-item">
                    <h2 class="title">Select the best experience for your ride!</h2>
                    <p class="description">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna.
                    </p>
                  </div>
                  <div class="carousel-item">
                    <h2 class="title">Select the best experience for your ride!</h2>
                    <p class="description">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 order-1 order-lg-2">
            <div class="row justify-content-center h-100 align-items-center">
              <div class="form-style col-lg-8 py-4">
                <div class="text-center">
                  <img
                    src="static/images/logo-horizontal.svg"
                    width="200"
                    class="mx-auto mb-5"
                    alt=""
                  />
                  <div class="text-center">
                    <h1 class="font-22 mb-3">
                      Select the best experience for your ride!
                    </h1>
                    <p class="text-secondary">
                      <!-- Select the best experience for your ride! -->
                    </p>
                  </div>
                </div>

                <Form @submit="getLogin()" ref="loginForm">
                  <div class="mb-4">
                    <div class="form-group">
                      <label>Mobile Number</label>
                      <span class="text-danger">*</span>
                      <div class="input-group">
                        <span class="input-group-text bg-white">
                          <img
                            src="static/images/mobile-icon.svg"
                            width="16"
                            class=""
                            alt=""
                          />
                          <span class="ms-2 font-bold">+91</span>
                        </span>
                        <Field
                          type="text"
                          name="phone"
                          placeholder="Mobile Number"
                          class="form-control mobile_no"
                          rules="required:mobile number|tenDigitNumber:10"
                          :validateOnInput="true"
                          v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                          v-on:keypress.enter="getLogin"
                          v-model="loginData.mobile_number"
                        />
                      </div>
                    </div>
                    <ErrorMessage name="phone" class="validation-msg mt-2" />

                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="Notification"
                      />
                      <label class="form-check-label" for="Notification">
                        Receive WhatsApp Notification
                      </label>
                    </div>
                  </div>
                  <button
                    id="login-id"
                    native-type="submit"
                    class="btn btn-primary w-100 mb-3"
                  >
                    {{ is_show ? "Register as Partner" : "Login" }}
                  </button>
                  <div class="text-center">
                    {{ is_show ? "Already have an account?" : "Don't have an account?" }}
                    <a
                      class="font-medium text-brand cursor-pointer"
                      v-on:click="handleLoginButton()"
                    >
                      {{ is_show ? "Login" : "Register as Partner" }}</a
                    >
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import "./login.scss";

import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "PortalLogin",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      image: "",
      index: 0,
      is_show: false,
      banner_images: [],
      loginData: {},
      setLoginButton: false,
    };
  },
  mounted() {
    this.getBanner();
    const mob_no = this.$sharedService.getMapValue("mobile_number");
    if (mob_no) {
      this.loginData.mobile_number = mob_no;
    }
  },
  methods: {
    handleLoginButton() {
      this.is_show = !this.is_show;
    },
    showCurrentImage(index) {
      for (var i in this.banner_images) {
        this.banner_images[i].isCurr = false;
        if (index == i) {
          this.banner_images[i].isCurr = true;
          this.image = this.banner_images[i].icon;
          this.index = index;
        }
      }
    },
    getBanner() {
      this.$api
        .getAPI({
          _action: "/banners/VENDOR/START_SCREEN",
        })
        .then((res) => {
          if (res.list && res.list.length > 0) {
            this.banner_images = res.list;
            this.image = res.list[0].icon;
            res.list[0].isCurr = true;
            var index = 0;
            setInterval(() => {
              if (this.banner_images.length == index) {
                index = 0;
              }
              this.image = this.banner_images[index].icon;
              this.index = index;
              index++;
            }, 2000);
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          // Handle the error here
        });
    },
    getLogin() {
      if (this.loginData.mobile_number.length < 10) {
        return;
      }
      this.loginData.dial_code = "+91";
      this.loginData.country_code = "in";
      this.$api
        .getAccesstoken({
          _action: "/register",
          _body: this.loginData,
          _buttonId: "login-id",
        })
        .then((res) => {
          this.$sharedService.setMapValue("mobile_number", this.loginData.mobile_number);
          this.$sharedService.setMapValue("is_agreement_show", res.is_agreement_show);
          this.$router.push("/otp");
        })
        .catch(() => {
          // Handle the error here
        });
    },
  },
};
</script>

