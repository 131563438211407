<script>
export default {
    name: "AppLogin",
    mounted() {
        localStorage.clear();
        localStorage.removeItem("access_token");

        // alert(2134567890);
        // console.log(this.$router.currentRoute.value.query.vendor_member_id);
        // this.$router.push('/new-route');
        this.$api
        .postAPI({
            _action: "/get-vendor-token",
            _body: { id:  this.$router.currentRoute.value.query.vendor_member_id},
            _hide_loader: true,
        })
        .then((res) => {
            localStorage.setItem("access_token", res.token);
            // console.log(res);
            this.$api
          .postAPI({
            _action: "/set-workshop",
            _body: { id: this.$router.currentRoute.value.query.workshop_id },
            _hide_loader: true,
          })
          .then((res) => {
            // console.log(res);
            
            localStorage.setItem("access_token", res.access_token);
            this.$store.commit("setAccessToken", res.token);
            // this.getMeCall(this.$router.currentRoute.value.query.vendor_member_id);
            this.$api
        .getAPI({
          _action: "me",

        })
        .then((res) => {
            console.log(res);
            
        //   var next_url = localStorage.getItem("next_url") ?? "";
        //   this.getRegisterCall();

          localStorage.setItem('workshop_id', this.$router.currentRoute.value.query.workshop_id ?? '');
        //   if (next_url) {
            this.$router.push(this.$router.currentRoute.value.query.url);
        //   } else {
            // this.$store.commit("setAccessToken", this.$access_token);
            // localStorage.setItem("page_type", "ONBOARDING");
            // this.$store.commit("setPageType", "ONBOARDING");

// /
        //   }
        //   this.$sharedService.clearMap();
        })
        .catch(() => {});
          })
          .catch(() => {});
            
        }).catch(() => {});
    }
}
</script>
<template>
    <div></div>
</template>